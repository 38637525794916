<template>
  <section id="section-print">
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :filename="`Comprovante de inscrição nº ${formulario.id || ''}`"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <div slot="pdf-content">
        <div class="p-5">
          <div class="row justify-content-center">
            <div class="col-12 border-bottom pb-1 mb-3">
              <div class="row justify-content-bettwen">
                <div class="col-auto align-self-end position-absolute">
                  <img class="img-imp-tacuru" src="../assets/images/tacuru.png" />
                </div>
                <div class="col align-self-end">
                  <h5 class="text-center mb-2">PREFEITURA MUNICIPAL DE TACURU</h5>
                </div>
              </div>
            </div>
            <!-- <div class="col-12">
              <h5 class="text-justify upper mb-3" style="font-size: 13px">
                A prefeitura Municipal de Tacuru - Estado de Mato Grosso do Sul, através da Comissão Municipal de Processo Seletivo de
                Professores, no uso de suas atribuições legais, nomeada pelo Decreto nº 112/2023, de 18 de dezembro de 2023, tornam público
                aos interessados que estão abertas as inscrições para o processo Seletivo de Cadastro de Reserva para Professores
                Temporários atuarem nas unidades escolares da Rede Municipal de Ensino, de acordo com as normas estabelecidas no presente
                Edital.
              </h5>
            </div> -->

            <!-- <div class="col-12">
              <h5 class="text-center mb-4" style="font-size: 15px">
                <span>Edital 01 - Abertura</span>
              </h5>
            </div> -->

            <div class="col-12 mb-4">
              <h4 class="text-center mb-3" style="font-size: 17px">PROTOCOLO DE INSCRIÇÃO</h4>
            </div>

            <div class="col-4">
              <p><span>Número:</span> {{ mountNumInsc(formulario.id) || '--' }}</p>
            </div>
            <div class="col-8">
              <p><span>Data de inscrição:</span> {{ mountDateTime(formulario.createdAt || formulario.created_at) }}</p>
            </div>
            <div class="col-4 mb-4">
              <p><span>Local:</span> {{ getValue('local') }}</p>
            </div>
            <div class="col-8 mb-4">
              <p><span>Cargo:</span> {{ getValue('cargo') }}</p>
            </div>

            <div class="col-5">
              <p><span>Nome:</span> {{ getValue('nome') }}</p>
            </div>
            <div class="col-3">
              <p><span>CPF: </span> {{ getValue('cpf') }}</p>
            </div>
            <div class="col-4">
              <p><span>RG:</span> {{ getValue('rg') }} <span class="pl-2">Orgão:</span> {{ getValue('orgao_emissor') }}</p>
            </div>

            <div class="col-5">
              <p><span>Data de Nascimento:</span> {{ mountDate(getValue('data_nascimento')) }}</p>
            </div>
            <div class="col-3">
              <p><span>Sexo:</span> {{ getValue('sexo') === 'M' ? 'Masculino' : 'Feminino' }}</p>
            </div>
            <div class="col-4">
              <p><span>Estado civil:</span> {{ getValue('estado_civil') }}</p>
            </div>

            <div class="col-5">
              <p><span>Telefone celular:</span> {{ mountPhone(getValue('telefone_celular')) }}</p>
            </div>
            <div class="col-7">
              <p><span>Telefone residencial:</span> {{ mountPhone(getValue('telefone_residencial')) }}</p>
            </div>

            <div class="col-12 mb-4">
              <p><span>Email:</span> {{ getValue('email') }}</p>
            </div>

            <div class="col-3">
              <p><span>CEP:</span> {{ getValue('cep') }}</p>
            </div>
            <div class="col-2">
              <p><span>Estado:</span> {{ getValue('uf') }}</p>
            </div>
            <div class="col-3">
              <p><span>Cidade:</span> {{ getValue('cidade') }}</p>
            </div>
            <div class="col-4">
              <p><span>Bairro:</span> {{ getValue('bairro') }}</p>
            </div>

            <div class="col-8">
              <p><span>Endereço:</span> {{ getValue('endereco') }}</p>
            </div>
            <div class="col-4">
              <p><span>Número:</span> {{ getValue('numero') }}</p>
            </div>

            <div class="col-12">
              <p><span>Complemento:</span> {{ getValue('complemento') }}</p>
            </div>

            <div class="col-12 mt-4">
              <p>
                <span>Nacionalidade brasileira:</span>
                {{ yesOrNo(getValue('nacionalidade_brasileira')) }}
              </p>
            </div>
            <div class="col-12">
              <p>
                <span>Nacionalidade estrangeira:</span>
                {{ yesOrNo(getValue('nacionalidade_estrangeira')) }}
              </p>
            </div>
            <div class="col-12">
              <p>
                <span>Obrigações eleitorais:</span>
                {{ yesOrNo(getValue('obrigacoes_eleitorais_em_dia')) }}
              </p>
            </div>
            <div class="col-12 mb-4" v-if="getValue('sexo') === 'M' || getValue('obrigacoes_militares_em_dia') === 'S'">
              <p>
                <span>Obrigações militares:</span>
                {{ yesOrNo(getValue('obrigacoes_militares_em_dia')) }}
              </p>
            </div>
            <div class="col-12">
              <p>
                <span>Possui necessidades especiais?</span>
                {{ yesOrNo(getValue('possui_necessidades_especiais')) }}
              </p>
            </div>
            <div class="col-12" v-if="getValue('possui_necessidades_especiais') === 'S'">
              <p>
                <span>Condições de que necessita para a realização das provas:</span>
                {{ getValue('detalhe_necessidade_especial') }}
              </p>
            </div>
            <div class="col-12">
              <p>
                <span>Tenho direito a isenção da taxa de inscrição:</span>
                {{ yesOrNo(getValue('isencao_taxa_inscricao')) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </vue-html2pdf>
  </section>
</template>

<script>
import camelize from 'camelize'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: 'ImpressaoComp',

  components: {
    VueHtml2pdf
  },

  props: {
    formulario: {
      type: Object
    }
  },

  methods: {
    mountPhone(phone) {
      const count = phone?.length
      if (!phone) return '--'

      const toSlice = count === 11 ? 7 : 6
      const ddd = phone.slice(0, 2)
      const first = phone.slice(2, toSlice)
      const second = phone.slice(toSlice, phone.length)
      return `(${ddd}) ${first}-${second}`
    },

    getValue(key) {
      return this.formulario?.[key || camelize(key)] || '--'
    },

    generatePdf() {
      this.$refs.html2Pdf.generatePdf()
    },

    yesOrNo(resp) {
      return resp === 'S' ? 'Sim' : 'Não'
    },

    mountDate(date) {
      return this.$f.invertData(date)
    },

    mountDateTime(date) {
      return this.$f.mountDate(date)
    },

    mountNumInsc(num) {
      return `${num}`?.padStart(6, '0')
    }
  }
}
</script>
<style lang="sass" scoped>
br
  margin-bottom: 10px
.border-bottom
  border-bottom: 1px solid black
p,h1,h2,h3,h4,h5,h6
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important
p
  font-size: 13px
  color: #000 !important
  font-weight: 300 !important
  span
    font-size: 14px
    font-weight: 600 !important
.position-absolute
  position: absolute
.img-imp-tacuru
  width: 120px
.img-imp
  width: 50px
.vue-html2pdf
  .layout-container
    background-color: #fff !important

  .content-wrapper
    background-color: #fff !important
</style>
