<template>
  <div class="area-boleto p-4">
    <Impressao :formulario="profile" ref="impressao" />

    <h5 class="bold mb-4">Arquivos para download</h5>

    <div class="row justify-content-between align-items-center py-2 bg-light">
      <div class="col-md-auto col-8 px-2 px-md-3">
        <p>Ficha de inscrição</p>
      </div>
      <div class="col-4 col-md-auto text-center px-0 px-md-3">
        <a class="btn btn-primary py-1 text-white pointer font-13" @click="createPDF">Download</a>
      </div>
    </div>

    <div class="row justify-content-between align-items-center py-2" v-if="isencao">
      <div class="col-md-auto col-8 px-2 px-md-3">
        <p>Comprovante de Isenção da Taxa de inscrição</p>
      </div>
      <div class="col-4 col-md-auto text-center px-0 px-md-3">
        <a
          class="btn btn-primary py-1 text-white pointer font-13"
          target="_blank"
          :href="`https://ibcgp-plataforma.sfo3.cdn.digitaloceanspaces.com/concurso-ps/${$city}/${$ps}/isencoes/${profile.id}.pdf`"
          >Download</a
        >
      </div>
    </div>

    <img v-if="isLoading" src="@/assets/images/loading.gif" class="is-loading" />

    <div
      :class="['row justify-content-between align-items-center py-2', (ind + (isencao ? 1 : 0)) % 2 && 'bg-light']"
      v-for="(item, ind) in list"
      :key="item.titulo"
    >
      <div class="col-md-10 col-8 px-2 px-md-3">
        <p class="font-13 ellipsis text-left">
          <span class="bold">{{ item.data }}</span>
          <span class="px-3"></span>
          {{ item.titulo }}
        </p>
      </div>
      <div class="col-4 col-md-auto text-center px-0 px-md-3">
        <a download target="_blank" :href="item.link" class="btn btn-primary py-1 font-13">Download</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import Impressao from '@/components/Impressao'

export default {
  components: {
    Impressao
  },

  data() {
    return {
      list: [],
      isLoading: false
    }
  },

  created() {
    this.getDownloads()
  },

  methods: {
    async createPDF() {
      this.$refs.impressao.generatePdf()
    },

    async getDownloads() {
      this.isLoading = true
      const { data } = await axios.get(`/formulario/${this.$city}/${this.$psCity}/publicacoes`).catch(() => ({ data: [] }))
      this.isLoading = false
      this.list = (data || []).map((d) => {
        const [ano, mes, dia] = d.data_publicacao.split('-')
        d.data = `${dia}/${mes}/${ano}`
        return d
      })
      this.list.unshift({
        data: '09/02/2024',
        titulo: 'Comunicado Oficial',
        link: 'https://ibcgp-plataforma.sfo3.cdn.digitaloceanspaces.com/concurso-ps/tacuru/ps2/publicacoes/Comunicado%20Oficial.PDF'
      })
    }
  },

  computed: {
    ...mapGetters({ profile: 'profile/getProfile' }),

    isencao() {
      return this.profile.isencao_taxa_inscricao === 'S'
    }
  }
}
</script>

<style lang="sass" scoped>
@media (max-width: 500px)
  p
    line-height: 18px
    text-align: left
  a
    margin: auto
    height: 22px
    display: inline-flex
    align-items: center
    justify-content: center
.area-boleto
  background-color: #fff
  padding: 25px 15px
  border-radius: 5px
  h5
    font-size: 18px
    text-align: center
.font-13
  font-size: 13px
.is-loading
  width: 30px
  margin-top: 30px
.ellipsis
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
</style>
