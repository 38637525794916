<template>
  <div class="area-restituicao">
    <img v-if="isLoading" src="@/assets/images/loading.gif" class="is-loading" />

    <div v-else class="pt-5">
      <ImpressaoReembolso :option="option" :form="form" ref="impressao" id="bg-white" class="bg-white" />

      <h5 class="mb-4">FORMULÁRIO DE REQUERIMENTO DE DEVOLUÇÃO DO VALOR DA TAXA DEINSCRIÇÃO</h5>

      <div class="row justify-content-center">
        <form @submit.prevent="salvar" class="col-md-6 col-lg-11 text-justify">
          Eu <span class="bold">{{ profile.nome }}</span
          >, portador (a) do RG n.º <span class="bold">{{ profile.rg }}</span
          >, e do CPF n.º <span class="bold">{{ profile.cpf }}</span
          >, inscrito(a) no Processo Seletivo Simplificado nº 01/2024, para o cargo de <span class="bold">{{ profile.cargo }}</span
          >, inscrição nº <span class="bold">{{ pad(profile.id, 6) }}</span
          >, regido pelo Edital de Abertura nº 001/2024, solicito a devolução do valor pago pela taxa de inscrição do referido Processo
          Seletivo Simplificado, em virtude do que consta no Edital nº 03/2024 – Processo Seletivo nº 01/2024, que dispõe sobre a anulação
          das provas aplicadas no dia 04/02/2024 e reaplicação das provas objetivas do Processo Seletivo Simplificado nº 01/2024. Dados para
          Contato: Telefone:
          <span class="bold">{{ profile.telefone_residencial || profile.telefone_celular || profile.telefone_comercial }}</span
          >. E-mail: <span class="bold">{{ profile.email }}</span
          >. DECLARO que estou ciente que o pedido de restituição da taxa de inscrição implica em desistência de minha participação no
          Processo Seletivo Simplificado nº 01/2024, ficando impedida de realização da prova objetiva que será reaplicada no dia 18/02/2024.
          <br /><br />

          <div class="row mb-3">
            <FieldOptions
              class="mb-2"
              id="restituicao"
              direction="flex-column d-flex"
              :isDisabled="!!form.id"
              :options="[
                { value: 1, label: `Opção 01 - Devolução na Conta Bancária do Candidato CPF n.º ${profile.cpf}` },
                { value: 2, label: 'Opção 02 - Devolução na Conta Bancária de Terceiros:' },
                { value: 3, label: 'Opção 03 - Chave PIX:' }
              ]"
              v-model="option"
              type="radio"
            />
          </div>

          <div v-if="option !== 3" class="row bg-light py-2">
            <div class="col-md-3"><FieldInput :isDisabled="!!form.id" label="Banco" :required="true" v-model="form.nomeBanco" /></div>
            <div class="col-md-2"><FieldInput :isDisabled="!!form.id" label="Agência" :required="true" v-model="form.numeroAgencia" /></div>
            <div class="col-md-2"><FieldInput :isDisabled="!!form.id" label="Conta" :required="true" v-model="form.numeroConta" /></div>
            <div class="col-md-2"><FieldInput :isDisabled="!!form.id" label="Dígito" :required="true" v-model="form.digitoConta" /></div>
            <div class="col-md-3">
              <FieldOptions
                class="mb-2"
                id="form-2"
                :isDisabled="!!form.id"
                :options="[
                  { value: 'cc', label: 'Conta Corrente' },
                  { value: 'cp', label: 'Conta Poupança' }
                ]"
                :required="true"
                v-model="form.tipoConta"
                type="radio"
              />
            </div>
            <div v-if="option === 2" class="col-md-3">
              <FieldInput :isDisabled="!!form.id" label="CPF" mask="###.###.###-##" :required="true" v-model="form.cpf" />
            </div>
          </div>

          <div v-else class="row bg-light py-2">
            <div class="col-md-4">
              <FieldInput :isDisabled="!!form.id" label="Chave PIX:" :required="true" v-model="form.chavePix" />
            </div>
          </div>

          <div class="text-center mt-4">
            <p v-if="form.statusReembolso" class="mb-1">Status do Reembolso: {{ mountStatus(form.statusReembolso) }}</p>

            <p class="mb-5">Tacuru/MS, {{ form.createdAt ? mountDate(form.createdAt) : today }} de fevereiro de 2024.</p>
            <div class="row justify-content-center">
              <div class="col-md-2" v-if="enablePrint">
                <span @click="imprimir" class="btn btn-success py-1 font-13 pointer">Imprimir</span>
              </div>
              <div v-else class="col-md-2">
                <button type="submit" class="btn btn-primary py-1 font-13">Salvar Solicitação</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import camelize from 'camelize'
import { mapGetters } from 'vuex'
import FieldInput from '@/components/fields/FieldInput'
import FieldOptions from '@/components/fields/FieldOptions'
import ImpressaoReembolso from '@/components/ImpressaoReembolso'

const form = {
  cpf: '',
  chavePix: '',
  nomeBanco: '',
  tipoConta: '',
  digitoConta: '',
  numeroConta: '',
  numeroAgencia: ''
}

export default {
  components: {
    FieldInput,
    FieldOptions,
    ImpressaoReembolso
  },

  data() {
    return {
      option: 1,
      form: { ...form },
      isLoading: false,
      enablePrint: false
    }
  },

  created() {
    this.getData()
  },

  methods: {
    async getData() {
      this.isLoading = true
      const { data } = await axios.get(`/formulario/${this.$city}/${this.$psCity}/area-candidato/dados-pedido-reembolso`)
      this.isLoading = false

      if (!data.length) return

      this.enablePrint = true
      const dados = camelize(data[0])
      this.option = dados.chavePix ? 3 : dados.cpf ? 2 : 1
      this.form = dados
    },

    mountStatus(status) {
      if (status === 'P') return 'Pendente'
      if (status === 'C') return 'Concluido'
      if (status === 'E') return 'Efetivado'
      return '--'
    },

    mountDate(date) {
      if (!date) return '--'
      return date.slice(8, 10)
    },

    async salvar() {
      const html = `<p class="text-danger">Está ciente que o pedido de restituição/devolução importa em desistência
        de minha participação no processo seletivo simplificado n. 01/2024, ficando
        impedido(a) de realizar a prova objetiva que será reaplicada no dia
        18/02/2024?</p>`
      const { isConfirmed } = await this.$sweet.sweetQuestionPersonalizado('', html)
      if (!isConfirmed) return

      this.$sweet.sweetWait('Realizando a solicitação.')

      const params = { ...this.form }

      if (this.option === 1) {
        params.cpf = ''
        params.chavePix = ''
      }

      if (this.option === 2) {
        params.chavePix = ''
      }

      if (this.option === 3) {
        params.cpf = ''
        params.nomeBanco = ''
        params.tipoConta = ''
        params.digitoConta = ''
        params.numeroConta = ''
        params.numeroAgencia = ''
      }

      const { status, data } = await axios
        .post(`/formulario/${this.$city}/${this.$psCity}/area-candidato/dados-pedido-reembolso`, params)
        .catch((e) => e.response)

      if (status >= 300 || isNaN(Number(data))) return this.$sweet.sweetNotification(data)

      this.enablePrint = true
      this.form.id = 'id'
      this.$sweet.sweetSuccess('Solicitação registrada.')
    },

    imprimir() {
      this.$refs.impressao.generatePdf()
    },

    pad(val, num) {
      return `${val}`.padStart(num, '0')
    }
  },

  computed: {
    ...mapGetters({ profile: 'profile/getProfile' }),

    today() {
      return this.pad(new Date().getDate(), 2)
    }
  }
}
</script>

<style lang="sass" scoped>
.area-restituicao
  background-color: #fff
  padding: 15px
  border-radius: 5px
  h5
    font-size: 16px
    text-align: center
</style>
