<template>
  <div class="minheight">
    <div v-if="profile.id">
      <nav class="navbar navbar-expand-lg navbar-light">
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarToggleExternalContent"
          aria-controls="navbarToggleExternalContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse" id="navbarToggleExternalContent">
          <ul class="navbar-nav mr-auto pt-1">
            <li class="nav-item" v-for="item in menu" :key="item.name">
              <router-link
                :class="['link-mobile', isCurrent(item.name) ? 'link-mobile-active' : '']"
                :is="item.isDisabled ? 'span' : 'router-link'"
                :to="{ name: item.name }"
              >
                {{ item.title }}
              </router-link>
            </li>

            <li class="nav-item mt-3">
              <p :class="['mb-0 pb-0', colorBoleto]">
                {{ statusBoleto }}
              </p>
            </li>
          </ul>
        </div>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto nav-pc">
            <li class="nav-item nav-item-pc py-5" v-for="item in menu" :key="item.name">
              <router-link
                :class="['item-menu px-2 py-5', isCurrent(item.name) ? 'btn-primary' : 'text-secondary btn-outline-secondary']"
                :is="item.isDisabled ? 'span' : 'router-link'"
                :to="{ name: item.name }"
              >
                {{ item.title }}
              </router-link>
            </li>

            <p :class="['mb-0 pb-0 ml-4', colorBoleto]">
              {{ statusBoleto }}
            </p>
          </ul>

          <div class="form-inline my-2 my-lg-0">
            <p class="mr-3 name">{{ profile.nome || profile.nomeCandidato }}, seja bem vindo a área do Candidato</p>
            <button class="btn btn-outline-danger my-2 my-sm-0 py-1" @click="logOut">Sair</button>
          </div>
        </div>
      </nav>

      <div class="text-center py-4">
        <div class="col-md-8 m-auto">
          <router-view />
        </div>
      </div>

      <Informacoes class="mt-4" />

      <div class="pb-5 text-center">
        <div class="col-md-6 pb-4 mx-auto">
          <p class="contact text-center pb-4 text-secondary">
            Se tiver alguma dúvida ou se tiver algum problema com sua inscricao, entrar em contato com o email
            <a :href="`mailto:${suporte}`">{{ suporte }}</a
            >.
          </p>
        </div>
      </div>

      <Footer />
    </div>
    <div v-else>
      <img src="../assets/images/loading.gif" alt="" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import Footer from '@/components/Footer'
import Informacoes from '@/components/Informacoes'

export default {
  components: {
    Footer,
    Informacoes
  },

  data() {
    return {
      suporte: 'suporte@ibcgp.org.br',
      duvidas: 'duvidas@ibcgp.org.br',
      loading: false
    }
  },

  async created() {
    if (this.profile.id) return

    const token = localStorage.getItem(`token-${this.$city}`)
    if (!token) this.logOut()

    axios.defaults.headers['Authorization'] = `Bearer ${token}`

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const response = error?.response?.message || error?.response?.data?.message || error?.response?.data || error?.response
        if (response === 'Token has expired') this.logout()
        return error
      }
    )

    const { data, status } = await axios.get(`/formulario/${this.$city}/${this.$psCity}/area-candidato/dados`).catch((e) => {
      console.log(e.response)
      return e.response
    })
    if (status > 299) return this.$router.push({ name: 'home' }).catch((e) => e)

    try {
      const [[{ inscricao }]] = data

      this.$store.dispatch('profile/setProfile', { ...inscricao })

      this.$router.push({ name: 'area-form' }).catch((e) => e)
    } catch (error) {
      this.logOut()
    }
  },

  methods: {
    validarBoleto() {
      if (!this.isDisabledBoleto) return
      const msg = '<p>Seu boleto ainda não foi disponibilizado, assim que estiver disponível para download o botão ficará ativo.</p>'
      this.$sweet.sweetMensagem(msg)
    },

    logOut() {
      localStorage.setItem(`token-${this.$city}`, '')
      this.$store.dispatch('profile/setProfile', {})
      this.$router.push({ name: 'home' })
    },

    isCurrent(route) {
      return this.$route.name === route
    }
  },

  computed: {
    ...mapGetters({ profile: 'profile/getProfile' }),

    statusBoleto() {
      const status = this.profile?.status_debito
      if (status === 'Q') return 'Boleto ref. taxa de inscrição quitado'
      if (status === 'A') return 'Boleto ref. taxa de inscrição aguardando emissão'
      if (status === 'E') return 'Boleto ref. taxa de inscrição emitido/enviado para seu e-mail'
      return ''
    },

    menu() {
      return [
        { isDisabled: false, title: 'Downloads', name: 'area-form' },
        { isDisabled: false, title: 'Títulos', name: 'area-titulos' },
        { isDisabled: true, title: 'Impugnação', name: 'area-impugnacao' },
        { isDisabled: this.profile.status_debito !== 'Q', title: 'Restituição', name: 'area-restituicao' },
        { isDisabled: false, title: 'Senha', name: 'area-senha' }
      ]
    },

    colorBoleto() {
      const status = this.profile?.status_debito
      if (status === 'A') return 'text-secondary' // laranja
      if (status === 'E') return 'text-primary' // azul
      if (status === 'Q') return 'text-success' // verde
      return ''
    },

    isDisabledBoleto() {
      return this.profile.situacaoBoleto !== 'E' && this.profile.situacaoBoleto !== 'Q'
    }
  },

  watch: {
    'this.$route.name'() {
      if (!this.profile.id) this.logOut()
    }
  }
}
</script>

<style lang="sass" scoped>
img
  width: 50px
  position: fixed
  left: 50%
  top: 50%
  transform: translateY(-50%) translateX(-50%)
.contact
  a:hover
    color: #57b846 !important
span
  font-size: 13px !important
  opacity: .5
  cursor: no-drop
.link-mobile:active, .link-mobile:hover
  color: #57b846 !important
.link-mobile-active
  color: #57b846 !important

.active
  a
    font-weight: bold !important
    color: #fff !important
a:hover
  color: #fff !important
ul
  li
    list-style: none
    line-height: none
    align-self: baseline

.navbar
  box-shadow: 1px 1px 2px 1px rgba(45,45,45,.25)
.minheight
  min-height: 100vh !important
.name
  color: #555 !important
.nav-item
  align-self: baseline !important
  margin-bottom: 0px !important
  a
    font-size: 13px !important
  p
    margin-top: -10px !important
    font-weight: 400 !important
.navbar-light
  background-color: #fff !important
.item-menu
  border-right: 1px solid #ccc
.nav-item-pc
  line-height: unset !important
  padding: 0px !important
  padding-top: 3px !important
  padding-bottom: 3px !important
.btn-outline-secondary:hover
  color: #fff !important
  background-color: #868e96
  border-color: #868e96
.btn-outline-secondary
  background-color: #dfdfdf
.form-inline, p
  margin-bottom: 0px !important
.nav-pc
  p
    align-self: center !important
  li
    overflow: hidden !important
  li:first-child
    border-top-left-radius: 10px !important
    border-bottom-left-radius: 10px !important
  li:nth-last-child(-n+2)
    border-top-right-radius: 10px !important
    border-bottom-right-radius: 10px !important
</style>
