<template>
  <div class="area-senha">
    <h4 class="mb-4">Altere sua Senha</h4>

    <div class="row justify-content-center">
      <form @submit.prevent="cadastrarSenha" class="col-md-6 col-lg-5">
        <div>
          <FieldInput
            label="Senha Antiga"
            type="password"
            :required="true"
            v-model="senhaAntiga"
            placeholder="Informe aqui sua senha antiga:"
            class="mb-3"
          />
          <FieldInput
            class="mb-3"
            label="Nova Senha"
            type="password"
            :required="true"
            v-model="senhaNova"
            placeholder="Informe aqui sua nova senha:"
          />
          <FieldInput
            label="Repetir Nova Senha"
            type="password"
            :required="true"
            v-model="repetirNenhaNova"
            placeholder="Repita sua nova senha:"
          />
        </div>

        <div class="text-center mt-4">
          <button type="submit" class="btn btn-primary py-1">Salvar</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import FieldInput from '@/components/fields/FieldInput'

export default {
  components: {
    FieldInput
  },

  data() {
    return {
      senha: '',
      senhaNova: '',
      senhaAntiga: '',
      repetirNenhaNova: ''
    }
  },

  methods: {
    async cadastrarSenha() {
      if (this.repetirNenhaNova !== this.senhaNova)
        return this.$sweet.sweetNotification('A nova senha deve ser igual ao campo Repetir senha.')

      const params = {
        novaSenha: this.senhaNova,
        senhaAtual: this.senhaAntiga
      }
      this.$sweet.sweetWait('Estamos salvando sua senha')
      const { status } = await axios.put('/formulario/tacuru/processoseletivo2/area-candidato/senha', params)
      if (status > 299) return this.$sweet.sweetError()

      this.senha = ''
      this.senhaNova = ''
      this.senhaAntiga = ''
      this.repetirNenhaNova = ''
      this.$sweet.sweetSuccess('Senha salva com sucesso')
      this.$router.push({ name: 'area-form' })
    }
  },

  computed: {
    ...mapGetters({ profile: 'profile/getProfile' })
  }
}
</script>

<style lang="sass" scoped>
.area-senha
  background-color: #fff
  padding: 15px
  border-radius: 5px
  h5
    font-size: 16px
    text-align: center
</style>
