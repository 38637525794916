<template>
  <div class="limiter">
    <div class="container-login100">
      <button data-toggle="modal" data-target="#modalRecurso" id="btnModalRecurso" />

      <div class="wrap-login100 align-items-center">
        <div data-tilt class="login100-pic js-tilt">
          <img alt="IMG" src="../assets/images/tacuru.png" />
        </div>

        <div class="login100-form validate-form">
          <span class="login100-form-title">{{ $inscricaoAberta ? 'Ficha de inscrição' : 'Inscrições encerradas' }}</span>

          <FieldInputLogin
            :required="true"
            placeholder="CPF"
            icon-class="fa fa-address-card"
            :is-disabled="false"
            type="text"
            id="cpf-login"
            v-if="$inscricaoAberta"
            mask="###.###.###-##"
            :blur="validarCPF"
            v-model="cpf"
          />

          <div class="container-login100-form-btn">
            <button
              @click="!isDisabled && preencherFormulario()"
              v-if="$inscricaoAberta"
              :disabled="isDisabled"
              :class="['login100-form-btn', isDisabled ? 'cursor-disabled' : '']"
            >
              {{ loading ? 'Validando CPF...' : 'Acessar formulário' }}
            </button>
            <button v-else class="login100-form-btn" data-toggle="modal" data-target="#modalLogin">Área do Candidato</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Impressao from './Impressao'
import FieldInputLogin from './fields/FieldInputLogin'

export default {
  name: 'LoginComp',

  components: {
    Impressao,
    FieldInputLogin
  },

  data() {
    return {
      id: '',
      cpf: '',
      email: '',
      recurso: '',
      repeatEmail: '',
      dataNascimento: '',
      fields: {},
      impressao: {},
      loading: false,
      isDisabled: true
    }
  },

  methods: {
    async validarCPF(cpf) {
      if (!cpf || !this.isDisabled) return
      if (!this.$f.testaCPF(cpf)) return this.$sweet.sweetNotification('CPF inválido')

      this.loading = true
      const { data } = await axios.get(`/formulario/${this.$city}/${this.$psCity}/validar-inscricao-cpf/${cpf}`)
      this.loading = false
      if (isNaN(data)) return (this.isDisabled = false)

      const { value, dismiss } = await this.$sweet.sweetQuestionOptions(
        `
          <h5>Já existe inscrição realizada para este CPF.</h5>
          <p>ATENÇÃO! A realização de nova inscrição importará em indeferimento da inscrição anterior, nos termos do Edital.</p>
        `
      )

      const params = { email: '', senha: '' }

      if (dismiss === 'cancel') {
        const callback = () => {
          params.email = document.getElementById('swal-email').value
          params.senha = document.getElementById('swal-senha').value
        }
        const { value: isConfirmed } = await this.$sweet.sweetLogin(
          `
            <h5 class="mb-3">Para prosseguir com a nova inscrição, informe seu email e senha.</h5>

            <input type="email" id="swal-email" class="form-control mb-2" placeholder="Email:">
            <input type="password" id="swal-senha" class="form-control mb-2" placeholder="Senha:">
          `,
          callback
        )

        if (!isConfirmed) return

        this.$sweet.sweetWait('Estamos validando o seu acesso.')
        const { data: respToken, status: statusToken } = await axios
          .get(`/formulario/${this.$city}/${this.$psCity}/area-candidato/login`, { params })
          .catch((e) => {
            return { status: 500, data: e.response?.data }
          })

        if (statusToken > 299) return this.$sweet.sweetNotification(respToken)

        axios.defaults.headers['Authorization'] = `Bearer ${respToken.token}`
        this.$sweet.sweetClose()
        this.preencherFormulario(true)
      }
      if (value) return document.getElementById('modalLoginClose').click()
    },

    setData(formulario) {
      const numeroInscricao = formulario.id
      const nome = formulario.nome_candidato
      const funcao = formulario.funcao_desejada
      const createdAt = formulario.created_at.split(' ')
      const [ano, mes, dia] = createdAt[0].split('-')
      const [anoN, mesN, diaN] = formulario.data_nascimento.split('-')
      this.impressao = {
        nome,
        form: 'login',
        funcao: funcao,
        numeroInscricao,
        email: this.email,
        dataInscricao: `${dia}/${mes}/${ano}`,
        dataNascimento: `${diaN}/${mesN}/${anoN}`,
        ...formulario,
        cpf: this.cpf
      }
    },

    preencherFormulario(cancelarInscricao) {
      const profileParams = { cpf: this.cpf, cancelarInscricao }
      this.$store.dispatch('profile/setProfile', profileParams)
      this.$router.push({ name: 'form' })
    }
  },

  watch: {
    cpf: {
      handler(cpf) {
        if (cpf.length !== 14) return
        document.getElementById('cpf-login').blur()
      }
    }
  }
}
</script>

<style lang="sass">
.container-login100
  background-size: cover
  background-image: url('../assets/images/fundo-monocromatico.avif')
.limiter
  background-color: #f2f2f2
.input100
  border-radius: 15px !important
.login100-form
  width: 50% !important
.login100-form-btn
  background-color: #469338 !important
  border-radius: 15px !important
.login100-form-title
  padding-bottom: 30px !important
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
.wrap-login100
  box-shadow: 1px 1px 10px 3px rgba(45,45,100,.25)
  background-color: #fbfbfb
  padding: 100px !important
.cursor-disabled
  cursor: no-drop !important
  opacity: .5
@media (max-width: 500px)
  .login100-form
    width: 100% !important
  .wrap-login100
    padding: 50px 25px !important
</style>
