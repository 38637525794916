import Senha from '@/views/area/Senha'
import HomeView from '@/views/HomeView'
import FormView from '@/views/FormView'
import AreaView from '@/views/AreaView'
import Boleto from '@/views/area/Boleto'
import Titulos from '@/views/area/Titulos'
import Download from '@/views/area/Download'
import Restituicao from '@/views/area/Restituicao'

export default [
  // Home
  { path: '/', component: HomeView, name: 'home' },

  // Form inscrição
  { path: '/formulario', component: FormView, name: 'form' },

  // Area do candidato
  {
    path: '/area-candidato',
    name: 'area-candidato',
    component: AreaView,
    children: [
      { path: 'senha', component: Senha, name: 'area-senha' },
      { path: 'boleto', component: Boleto, name: 'area-boleto' },
      { path: 'formulario', component: Download, name: 'area-form' },
      { path: 'envio-de-titulos', component: Titulos, name: 'area-titulos' },
      { path: 'restituicao', component: Restituicao, name: 'area-restituicao' }
    ]
  },

  { path: '*', component: HomeView, name: 'home' }
]
