import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import Sweet from '@/assets/js/sweet'
import store from '@/store'
import VueMask from 'v-mask'
import Funcoes from '@/assets/js/functions'
import vSelect from 'vue-select'
import VueRouter from 'vue-router'
import 'vue-select/dist/vue-select.css'
import routes from '@/routes'
const baseApiURL = 'https://api.ibcgp.org.br'
axios.defaults.baseURL = baseApiURL
axios.defaults.headers.common['access'] = 'tacuru'

Vue.component('SelectComp', vSelect)
Vue.prototype.$sweet = new Sweet()
Vue.prototype.$siteInstituto = 'https://ibcgp.org.br'
Vue.prototype.$siteCidade = 'https://www.tacuru.ms.gov.br/'
Vue.prototype.$f = new Funcoes()
Vue.prototype.$inscricaoAberta = false
const city = 'tacuru'
Vue.prototype.$ps = 'ps2'
Vue.prototype.$city = city
Vue.prototype.$psCity = 'processoseletivo2'
Vue.use(VueRouter)
Vue.use(VueMask)

const router = new VueRouter({ routes, base: '/' })

new Vue({
  store,
  router,
  render: (h) => h(App)
}).$mount('#app')
