<template>
  <div id="app">
    <Login />
    <ValidarInscricao />
    <Footer />
  </div>
</template>

<script>
import Login from '@/components/Login'
import Footer from '@/components/Footer'
import ValidarInscricao from '@/components/ValidarInscricao'

export default {
  name: 'HomeComp',
  components: {
    Login,
    Footer,
    ValidarInscricao
  },

  data() {
    return {
      page: 'login',
      login: { email: '', form: 'login', cpf: '' }
    }
  }
}
</script>
<style lang="sass" scoped>
#app
  position: relative
</style>
