<template>
  <div :class="[isHome ? 'pos-fixed' : '', 'header w-100 pl-4 row justify-content-between']">
    <div class="col-auto">
      <a target="_blank" :href="$siteInstituto">
        <img src="../assets/images/i.jpg" />
      </a>
    </div>
    <p class="version text-dark position-absolute">v.{{ pkg.version }}</p>
  </div>
</template>

<script>
import pkg from '../../package.json'

export default {
  name: 'FooterComp',

  data() {
    return {
      pkg
    }
  },

  computed: {
    isHome() {
      const footerFloat = ['area-form', 'area-senha', 'area-boleto', 'home']
      return footerFloat.includes(this.$route.name)
    }
  }
}
</script>
<style lang="sass" scoped>
.version
  right: 20px !important
  bottom: 18px !important
  opacity: .5 !important
  font-size: 10px
  position: absolute !important


.header
  bottom: 0px
  color: white
  margin: 0px !important
  background-color: #fff
  position: relative
  padding: 5px 0px !important
  box-shadow: 1px 1px 5px 2px rgba(45,45,45,.15)
  img
    width: 150px
    border-radius: 3px

@media (max-width: 500px)
  .header
    justify-content: center !important
    img
      margin-top: 5px
      width: 130px !important
</style>
