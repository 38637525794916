import { render, staticRenderFns } from "./Senha.vue?vue&type=template&id=81dbede0&scoped=true&"
import script from "./Senha.vue?vue&type=script&lang=js&"
export * from "./Senha.vue?vue&type=script&lang=js&"
import style0 from "./Senha.vue?vue&type=style&index=0&id=81dbede0&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81dbede0",
  null
  
)

export default component.exports