<template>
  <div>
    <Impressao :formulario="impressao" ref="impressao" id="bg-white" class="bg-white" />

    <div class="main">
      <div class="container px-0 mt-3">
        <form @submit.prevent="salvar" class="form">
          <div class="row">
            <div class="col-md-6 px-5 pt-4 pb-1" v-for="(form, ind) in forms" :key="`${ind}-form`">
              <h2>{{ form.titulo }}</h2>

              <div class="row">
                <div :class="[input.class]" v-for="(input, indInput) in form.inputs" :key="`${indInput}-input`">
                  <div :class="input.conditional ? (input.conditional(formulario) ? 'enter-effect mb-3' : 'out-effect') : 'mb-3'">
                    <component
                      :is="input.component"
                      v-if="input.conditional ? input.conditional(formulario) : true"
                      :required="input.required || (formulario.sexo === 'M' && input.key === 'obrigacoesMilitares')"
                      :is-disabled="input.isDisabled || (!isValidBirthDate && input.key !== 'dataNascimento')"
                      :upper-case="input.upperCase"
                      :options="input.options"
                      :accept="input.accept"
                      :mask="input.mask"
                      :type="input.type"
                      :class="
                        (formulario.situacaoInscricao === 'I' && !formulario[input.key] && input.required) ||
                        (input.key === 'nomeCandidato' && !isValidName && 'incomplet')
                      "
                      :label="input.label"
                      v-model="formulario[input.key]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="d-inline-flex justify-content-center align-items-center w-100 height-30 mt-2"
            v-for="item in checkbox"
            :key="item.key"
          >
            <label class="text-right pointer mr-2 form-check-label w-55" :for="item.key">
              {{ item.label }}
            </label>

            <span class="d-inline-flex align-items-center w-45">
              <input class="pointer mb-1 check-confirm" type="checkbox" :id="item.key" v-model="formulario[item.key]" />
              <a class="pl-2" :href="item.link" target="_blank">{{ item.textLink }}</a>
            </span>
          </div>

          <div v-if="!isDisabled" class="col-12 text-center pb-4">
            <button
              type="submit"
              :disabled="!isConfirmed || !isValidBirthDate"
              :class="['btn mt-4', !isConfirmed || !isValidBirthDate ? 'btn-secondary' : 'btn-primary']"
            >
              Cadastrar
            </button>
          </div>

          <div v-else-if="formulario.cpf" class="col-12 text-center pb-4">
            <span class="btn mt-4 btn-secondary pointer" @click="logOut()">Sair</span>

            <!-- <span class="mx-2"></span> -->

            <!-- <span @click="getData()" class="btn mt-4 btn-primary pointer">Imprimir</span> -->
          </div>

          <div class="row justify-content-center">
            <div class="col-6">
              <p class="contact text-center pb-4 text-secondary">
                Se tiver alguma dúvida ou se tiver algum problema com sua inscricao, entrar em contato com o email
                <a :href="`mailto:${suporte}`">{{ suporte }}</a
                >.
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>

    <Informacoes class="mt-4" />

    <Footer />
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import Footer from '@/components/Footer'
import Impressao from '@/components/Impressao'
import Informacoes from '@/components/Informacoes'

import { checkbox, formLeft as inputsLeft, formRight as inputsRight } from './formInputs'

export default {
  name: 'FormComp',

  components: {
    Footer,
    Impressao,
    Informacoes
  },

  data() {
    return {
      suporte: 'suporte@ibcgp.org.br',
      duvidas: 'duvidas@ibcgp.org.br',
      checkbox,
      isDisabled: false,
      isLoadingId: false,
      forms: [
        {
          titulo: 'Dados pessoais',
          inputs: inputsLeft
        },
        {
          titulo: 'Documentação',
          inputs: inputsRight
        }
      ],
      formulario: {
        cep: '',
        uf: '',
        cidade: '',
        dataNascimento: '',
        sexo: '',
        email: '',
        nomeCandidato: '',
        rg: '',
        orgaoEmissor: '',
        cpf: '',
        endereco: '',
        bairro: '',
        numero: '',
        local: '',
        complemento: '',
        estadoCivil: '',
        telefoneResidencial: '',
        telefoneCelular: '',
        cargo: '',
        nivel: '',
        nacionalidadeBrasileira: '',
        nacionalidadeEstrangeira: '',
        obrigacoesEleitorais: '',
        obrigacoesMilitares: '',
        necessidadesEspeciais: '',
        detalheNecessidadesEspeciais: '',
        isencaoTaxaInscricao: ''
      },
      impressao: {}
    }
  },

  created() {
    if (!this.profile.cpf) return this.$router.push({ name: 'home' })

    this.formulario = { ...this.formulario, ...this.profile }
  },

  computed: {
    ...mapGetters({ profile: 'profile/getProfile' }),

    isValidName() {
      const str = this.formulario?.nomeCandidato?.replace(/ /g, '')
      const regex = /\W|\d|_/
      return !regex.test(str)
    },

    isValidBirthDate() {
      const [dia, mes, ano] = this.formulario?.dataNascimento?.split('/') || ''
      if (!dia || !mes || !ano || ano.length < 4) return false

      const dataAtual = new Date()
      const limitDate = new Date(dataAtual.getFullYear() - 10, dataAtual.getMonth(), dataAtual.getDate())
      const birthDate = new Date(ano, mes - 1, dia)
      if (birthDate > limitDate) return false
      return true
    },

    isConfirmed() {
      const inputs = [...this.forms.map(({ inputs }) => inputs).flat()]
      const isFill = inputs.reduce((acc, { key, required, conditional }) => {
        if (!acc) return acc

        const isVisible = conditional ? conditional(this.formulario) : true
        if (!this.formulario[key] && required && isVisible) return false
        return true
      }, true)

      return isFill
    },

    isValidMail() {
      var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
      return Boolean(this.formulario.email.match(mailformat))
    }
  },

  methods: {
    logOut() {
      // this.$store.dispatch('profile/setProfile', {})
      this.$router.push({ name: 'home' })
    },

    getData() {
      axios.get(`/validar-registro-existente-usando-cpf/${this.formulario.cpf}`).then(({ data }) => {
        const formulario = data[0]
        const numeroInscricao = formulario.id
        const nome = formulario.nome_candidato
        const createdAt = formulario.created_at.split(' ')
        const [ano, mes, dia] = createdAt[0].split('-')
        const [anoN, mesN, diaN] = formulario.data_nascimento.split('-')
        this.impressao = {
          nome,
          numeroInscricao,
          dataInscricao: `${dia}/${mes}/${ano}`,
          dataNascimento: `${diaN}/${mesN}/${anoN}`,
          email: this.email,
          form: 'login',
          ...formulario,
          cpf: this.formulario.cpf
        }

        this.$refs.impressao.generatePdf()
      })
    },

    async salvar() {
      if (!this.isValidName || !this.isValidMail || !this.isValidBirthDate) {
        let concatMsg = ''
        if (!this.isValidName) concatMsg = 'seu nome'
        if (!this.isValidMail) concatMsg = 'seu email'
        if (!this.isValidBirthDate) concatMsg = 'sua data de nascimento'
        return this.$sweet.sweetMensagem(`Por favor, verifique se ${concatMsg} está correto.`)
      }

      const msg = 'Confirma que preencheu todos seus dados corretamente?'
      const html = `<p>O preenchimento incorreto implicará no indeferimento da inscrição. 
        Caso seus dados estejam corretos, receberá o boleto por e-mail em até 24 Horas.</p>`

      const { isConfirmed } = await this.$sweet.sweetQuestionPersonalizado(msg, html)
      if (!isConfirmed) return

      this.$sweet.sweetWait('Aguarde enquanto salvamos suas informações.')

      const [dia, mes, ano] = this.formulario.dataNascimento.split('/')
      const params = {
        ...this.formulario,
        dataNascimento: `${ano}-${mes}-${dia}`,
        cargo: `${this.formulario.nivel} - ${this.formulario.cargo}`
      }

      const { data, status } = await axios.post('formulario/tacuru/processoseletivo2', params).catch((e) => e.response)
      this.formulario.id = data?.id

      // const { comprovanteescolaridade, isencaotaxainscricao } = params
      // if (isencaotaxainscricao) await this.saveDoc('isencaotaxainscricao', isencaotaxainscricao)
      // if (comprovanteescolaridade) await this.saveDoc('comprovanteescolaridade', comprovanteescolaridade)

      if (status >= 200 && status < 300) {
        this.isDisabled = true
        this.$sweet.sweetSuccess(
          '<h5 class="mb-3">Inscrição realizada com sucesso</h5><h6 class="mb-1">Para baixar sua ficha de inscrição acesse a área do candidato utilizando a senha que recebeu no seu email.</h6>'
        )
        return
      }

      this.$sweet.sweetMensagem(data || 'Erro ao salvar os dados, tente novamente. Caso o erro persista, entre em contato conosco.')
    },

    async saveDoc(nome, arquivo) {
      const path = `https://plataforma.ibcgp.org.br/api/pub/formulario/tacuru/formconcursocamara/${this.formulario.id}/arquivo/${nome}`
      const headers = { 'Content-Type': 'multipart/form-data' }
      const dataForm = new FormData()
      dataForm.append('file', arquivo)
      await axios.post(`${path}`, dataForm, { headers }).catch((err) => err)
    }
  },

  watch: {
    'formulario.cep': {
      async handler(cep) {
        const filtered = cep.replace(/[^0-9]+/g, '')
        if (filtered.length !== 8) return

        const location = await fetch(`https://viacep.com.br/ws/${filtered}/json/`).then((r) => r.json())

        this.formulario.cidade = location?.localidade || ''
        this.formulario.uf = location?.uf || ''
        this.formulario.bairro = location?.bairro || ''
        this.formulario.endereco = location?.logradouro || ''
      }
    }
  }
}
</script>

<style lang="sass">
.main
  background-color: #f2f2f2 !important
h2
  margin-bottom: 25px !important
  color: #082772 !important
.form-control
  max-height: 33px !important
  font-weight: 200
  font-size: 14px
  padding: 0px 10px !important
  border: 1px solid rgba(25,25,100, .3) !important
  &:focus, &:active
   border: 1px solid rgba(45,45,150, .8) !important
.text-area
  max-height: inherit !important
  padding-top: 5px !important
label, p
  margin-bottom: 3px
  font-weight: 600
  font-size: 14px
  color: #082772 !important
  user-select: none
.form
  background-color: #fcfcfc
  box-shadow: 1px 2px 10px 3px rgba(45,45,45,.15)
  border-radius: 5px
.check-confirm
  width: 20px
.height-30
  height: 30px
.w-55
  width: 55%
.w-45
  width: 45%
@media (max-width: 500px)
  .container
    width: 100% !important
    margin-left: 0px !important
    margin-right: 0px !important

.enter-effect
  max-height: 100vh
  transition: all 0.5s ease-out
  min-height: 58px
  opacity: 1
.out-effect
  min-height: 0px
  max-height: 0px
  overflow: hidden
  height: 0px
  opacity: 0
  transition: all 0.5s
.contact
  line-height: 13px
  font-size: 12px
.incomplet
  background-color: #ff6f6f1a
  border-radius: 5px
  .label
    color: #a80a0a !important
  .form-control, .vs__dropdown-toggle, .form-check-input
    border-color: #a80a0a !important
</style>
