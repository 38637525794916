import FieldInput from '@/components/fields/FieldInput'
import FieldOptions from '@/components/fields/FieldOptions'
import FieldSelect from '@/components/fields/FieldSelect'
// import FieldFile from '@/components/fields/FieldFile'

const required = true
const isDisabled = true
const classCols6 = 'col-xs-12 col-sm-12 col-md-6 col-lg-6'
const classCols4 = 'col-xs-12 col-sm-12 col-md-4 col-lg-4'
const classCols2 = 'col-xs-12 col-sm-12 col-md-2 col-lg-2'
const classCols12 = 'col-xs-12 col-sm-12 col-md-12 col-lg-12'

const optionsYesNo = [
  { label: 'Sim', value: 'S' },
  { label: 'Não', value: 'N' }
]

export const checkbox = []

const cargos = [
  { label: 'Letras', value: 'Letras' },
  { label: 'Matemática', value: 'Matemática' },
  { label: 'Educação Física', value: 'Educação Física' },
  { label: 'Geografia', value: 'Geografia' },
  { label: 'Artes', value: 'Artes' },
  { label: 'Ciências', value: 'Ciências' },
  { label: 'História', value: 'História' }
]

export const formLeft = [
  {
    required,
    class: classCols4,
    mask: '##/##/####',
    component: FieldInput,
    key: 'dataNascimento',
    label: 'Data Nascimento:'
  },
  {
    required,
    key: 'sexo',
    label: 'Sexo:',
    class: classCols4,
    component: FieldSelect,
    options: [
      { label: 'Feminino', value: 'F' },
      { label: 'Masculino', value: 'M' }
    ]
  },
  {
    required,
    key: 'estadoCivil',
    label: 'Estado civil:',
    class: classCols4,
    component: FieldSelect,
    options: [
      { label: 'Solteiro', value: 'solteiro' },
      { label: 'Casado', value: 'casado' },
      { label: 'Separado', value: 'separado' },
      { label: 'Divorciado', value: 'divorciado' },
      { label: 'Viúvo', value: 'viúvo' }
    ]
  },
  {
    required,
    key: 'email',
    type: 'email',
    label: 'Email:',
    class: classCols12,
    component: FieldInput
  },
  {
    required,
    type: 'text',
    label: 'Nome:',
    class: classCols12,
    key: 'nomeCandidato',
    component: FieldInput,
    placeholder: 'Informe o nome'
  },
  {
    required,
    key: 'rg',
    label: 'RG:',
    type: 'text',
    class: classCols4,
    component: FieldInput
  },
  {
    required,
    type: 'text',
    class: classCols4,
    key: 'orgaoEmissor',
    component: FieldInput,
    label: 'Orgão Emissor:'
  },
  {
    required,
    isDisabled,
    key: 'cpf',
    type: 'text',
    label: 'CPF:',
    class: classCols4,
    component: FieldInput,
    mask: '###.###.###-##'
  },
  {
    key: 'cep',
    required,
    mask: '##.###-###',
    label: 'CEP:',
    type: 'text',
    component: FieldInput,
    class: classCols4
  },
  {
    required,
    mask: 'AA',
    type: 'text',
    key: 'uf',
    upperCase: true,
    label: 'UF:',
    class: classCols2,
    component: FieldInput
  },
  {
    required,
    type: 'text',
    key: 'cidade',
    label: 'Cidade:',
    class: classCols6,
    component: FieldInput
  },
  {
    key: 'endereco',
    required,
    label: 'Endereço:',
    type: 'text',
    component: FieldInput,
    class: classCols12
  },
  {
    key: 'bairro',
    required,
    label: 'Bairro:',
    type: 'text',
    component: FieldInput,
    class: classCols6
  },
  {
    key: 'numero',
    required,
    label: 'Número:',
    mask: '##########',
    component: FieldInput,
    class: classCols6
  },
  {
    key: 'complemento',
    label: 'Complemento:',
    type: 'text',
    component: FieldInput,
    class: classCols12
  },
  {
    key: 'telefoneResidencial',
    label: 'Telefone residencial:',
    type: 'text',
    component: FieldInput,
    mask: '(##) ####-####',
    class: classCols6
  },
  {
    key: 'telefoneCelular',
    required,
    label: 'Telefone celular:',
    type: 'text',
    mask: '(##) #####-####',
    component: FieldInput,
    class: classCols6
  },
  {
    required,
    key: 'local',
    label: 'Local:',
    options: [
      { label: 'Zona Urbana', value: 'Zona Urbana' },
      { label: 'Zona Rural - Aldeia Jaguapiré', value: 'Zona Rural - Aldeia Jaguapiré' },
      { label: 'Zona Rural - Aldeia Sassoro', value: 'Zona Rural - Aldeia Sassoro' }
    ],
    component: FieldSelect,
    class: classCols12
  },
  {
    required,
    key: 'nivel',
    label: 'Nível:',
    options: [
      { label: 'Educação Infantil', value: 'Educação Infantil' },
      { label: 'Educação Básica - Anos Iniciais', value: 'Educação Básica anos Iniciais' },
      { label: 'Educação Básica - Anos Finais', value: 'Educação Básica anos Finais' }
    ],
    component: FieldOptions,
    class: classCols12,
    conditional: (form) => Boolean(form.local || form.local?.value)
  },
  {
    required,
    key: 'cargo',
    label: 'Cargo:',
    options: [...cargos, { label: 'Pedagogia', value: 'pedagogia' }],
    component: FieldOptions,
    class: classCols12,
    conditional: (form) => Boolean(form.nivel) && form.nivel === 'Educação Infantil'
  },
  {
    required,
    key: 'cargo',
    label: 'Cargo:',
    options: [...cargos, { label: 'Pedagogia', value: 'pedagogia' }, { label: 'Ará Vera Magistério', value: 'Ará Vera Magistério' }],
    component: FieldOptions,
    class: classCols12,
    conditional: (form) => Boolean(form.nivel) && form.nivel === 'Educação Básica anos Iniciais'
  },
  {
    required,
    key: 'cargo',
    label: 'Cargo:',
    options: [...cargos, { label: 'Teko Arandu', value: 'Teko Arandu' }],
    component: FieldOptions,
    class: classCols12,
    conditional: (form) => Boolean(form.nivel) && form.nivel === 'Educação Básica anos Finais'
  }
]

export const formRight = [
  {
    key: 'nacionalidadeBrasileira',
    required,
    label: 'Nacionalidade brasileira:',
    options: optionsYesNo,
    component: FieldSelect,
    class: classCols12
  },
  {
    key: 'nacionalidadeEstrangeira',
    required,
    label: 'Nacionalidade estrangeira:',
    options: optionsYesNo,
    component: FieldSelect,
    class: classCols12
  },
  {
    key: 'obrigacoesEleitorais',
    required,
    label: 'obrigações eleitorais estão em dia:',
    options: optionsYesNo,
    component: FieldSelect,
    class: classCols12
  },
  {
    key: 'obrigacoesMilitares',
    label: 'Obrigações militares estão em dia (sexo masculino):',
    options: optionsYesNo,
    component: FieldSelect,
    class: classCols12,
    conditional: (form) => form.sexo === 'M' || form.sexo === 'Masculino'
  },
  {
    key: 'necessidadesEspeciais',
    required,
    label: 'Possui necessidades especiais?',
    options: optionsYesNo,
    component: FieldSelect,
    class: classCols12
  },
  {
    key: 'detalheNecessidadesEspeciais',
    required,
    label: 'Descreva as condições de que necessita para a realização das provas:',
    type: 'textarea',
    component: FieldInput,
    class: classCols12,
    conditional: (fields) => fields.necessidadesEspeciais === 'S'
  }
  // {
  //   key: 'isencaoTaxaInscricao',
  //   required,
  //   label: 'Tenho direito a isenção da taxa de inscrição:',
  //   options: optionsYesNo,
  //   component: FieldSelect,
  //   class: classCols12
  // }
]

/* 
POSSIVEIS PARAMETROS
key           => propriuedade do objeto        
required      => Obrigatório    
label         => descrição do field
type          => tipo do field
options       => Opções para o caso seja select 
component     => Component a ser renderizado    
class         => Classes de estilo
conditional   => Validação para exibir ou não      
placeholder   => Caso seja campo de texto      
mask          => Mascara para validação
*/
