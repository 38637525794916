<template>
  <div>
    <p
      v-if="label"
      class="label bold"
      v-html="`${label} ${required ? '*' : ''}`"
    >
    </p>

    <input
      type="file"
      :required="required"
      :id="`${keyInput}-file`"
      :accept="accept"
      class="form-control max-25-px"
      @change="changeFile"
      :disabled="isDisabled"
      :placeholder="placeholder"
    >
  </div>
</template>
<script>

export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    accept: {
      type: String,
      default: ''
    },
    keyInput: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },

  methods: {
    changeFile () {
      const attachment = document.getElementById(`${this.keyInput}-file`).files[0]
      this.$emit('input', attachment)
    }
  }
}

</script>

<style lang="sass">
.max-25-px
  max-height: 25px !important
  padding-left: 0px !important
</style>