<template>
  <div>
    <SelectComp :required="required" v-model="field" :disabled="isDisabled" :options="options || []" :placeholder="placeholder" />

    <label class="font-12 select-label" v-if="field?.label">{{ field?.label }}</label>
  </div>
</template>
<script>
export default {
  props: {
    value: {},
    label: {},
    options: {},
    placeholder: {
      type: String,
      default: 'Selecione uma opção'
    },
    required: {},
    isDisabled: {}
  },

  data() {
    return {
      field: ''
    }
  },

  mounted() {
    this.field = this.options.find((item) => item?.value === this.value || '')
  },

  watch: {
    value: {
      handler(value) {
        if (this.$f.isEqual(value, this.field)) return
        this.field = this.options.find((item) => item?.value === value)
      }
    },

    field: {
      handler(field) {
        if (this.$f.isEqual(field, this.value)) return
        this.$emit('input', field?.value || '')
      }
    }
  }
}
</script>
<style>
.vs__selected-options {
  box-sizing: border-box;
}
.vs__search {
  box-sizing: border-box;
  max-height: 24px;
}
input::placeholder {
  color: #888 !important;
  font-weight: 400;
  font-size: 12px !important;
  opacity: 1;
}
.font-12 {
  font-size: 12px !important;
}
.select-label {
  text-align: justify !important;
}
</style>
